// NOTE:
// Our goal was not to use hardcodded paths inside our components
// whenever we want to provide a link or navigate the user on any page.
// Coudn't find any existing solution in ReactRouter library,
// since all of their examples are using hardcoded paths (<Navigate to='/user/settings' />),
// where we want to use something like this (<Navigate to={getPath(RouteNames.UserSettings)} />.
//
// So we implemented our own RouteNames enum and updated type-declarations of react-router,
// so that the 'name' property would be required in RouterObject
// and we could identify a route config (path, helpers, etc) just by the RouteName.
//
// In order to provide the helper function like 'getAbsolutePath(routeName: RouteNames)'
// we needed to separate our routes-tree declaration with the association of
// our react-components with the routes because of the circular-dependency problem:
// * pages/components require getAbsolutePath to provide navigation without hardcoded paths
// * getAbsolutPath requires a ReactRouter config with route names and paths to build fullpath
// * ReactRouter config requires pages/components in order to render them.
//
// Solving this problem our router config was split into two parts:
// 1. Routes - describing how routes are nested and what are their respective paths
// 2. Router - assigning components to the associated routes via RouteNames,
//    and providing a full usable Router for our application
//
// NON_ROUTABLE keys is a workaround that allows us to guarantee that
// the 'name' property will be present on all of our routes.
// It is not used when we try to navigate to some page,
// but used when we inject elements to our routes.
export var RouteNames;
(function (RouteNames) {
    RouteNames[RouteNames["NON_ROUTABLE_LAYOUT_GENERAL"] = 0] = "NON_ROUTABLE_LAYOUT_GENERAL";
    RouteNames[RouteNames["NON_ROUTABLE_LAYOUT_AUTHORIZED"] = 1] = "NON_ROUTABLE_LAYOUT_AUTHORIZED";
    RouteNames[RouteNames["NON_ROUTABLE_LAYOUT_HOME"] = 2] = "NON_ROUTABLE_LAYOUT_HOME";
    RouteNames[RouteNames["NON_ROUTABLE_NOT_FOUND"] = 3] = "NON_ROUTABLE_NOT_FOUND";
    RouteNames[RouteNames["PROFILE"] = 4] = "PROFILE";
    RouteNames[RouteNames["PROFILE_PORTFOLIOS"] = 5] = "PROFILE_PORTFOLIOS";
    RouteNames[RouteNames["PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION"] = 6] = "PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION";
    RouteNames[RouteNames["PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS"] = 7] = "PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS";
    RouteNames[RouteNames["PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY"] = 8] = "PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY";
    RouteNames[RouteNames["PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS"] = 9] = "PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS";
    RouteNames[RouteNames["PROFILE_FOLLOWERS"] = 10] = "PROFILE_FOLLOWERS";
    RouteNames[RouteNames["PROFILE_FOLLOWINGS"] = 11] = "PROFILE_FOLLOWINGS";
    RouteNames[RouteNames["PROFILE_ACTIVITY"] = 12] = "PROFILE_ACTIVITY";
    RouteNames[RouteNames["PROFILE_COPIERS"] = 13] = "PROFILE_COPIERS";
    RouteNames[RouteNames["MY_PROFILE"] = 14] = "MY_PROFILE";
    RouteNames[RouteNames["MY_PROFILE_PORTFOLIOS"] = 15] = "MY_PROFILE_PORTFOLIOS";
    RouteNames[RouteNames["MY_PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION"] = 16] = "MY_PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION";
    RouteNames[RouteNames["MY_PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS"] = 17] = "MY_PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS";
    RouteNames[RouteNames["MY_PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY"] = 18] = "MY_PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY";
    RouteNames[RouteNames["MY_PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS"] = 19] = "MY_PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS";
    RouteNames[RouteNames["MY_PROFILE_FOLLOWERS"] = 20] = "MY_PROFILE_FOLLOWERS";
    RouteNames[RouteNames["MY_PROFILE_FOLLOWINGS"] = 21] = "MY_PROFILE_FOLLOWINGS";
    RouteNames[RouteNames["MY_PROFILE_ACTIVITY"] = 22] = "MY_PROFILE_ACTIVITY";
    RouteNames[RouteNames["MY_PROFILE_COPIERS"] = 23] = "MY_PROFILE_COPIERS";
    RouteNames[RouteNames["SETTINGS"] = 24] = "SETTINGS";
    RouteNames[RouteNames["SETTINGS_PERSONAL_INFORMATION"] = 25] = "SETTINGS_PERSONAL_INFORMATION";
    RouteNames[RouteNames["SETTINGS_NOTIFICATIONS"] = 26] = "SETTINGS_NOTIFICATIONS";
    RouteNames[RouteNames["SETTINGS_SUBSCRIPTION"] = 27] = "SETTINGS_SUBSCRIPTION";
    RouteNames[RouteNames["AUTH"] = 28] = "AUTH";
    RouteNames[RouteNames["ROOT"] = 29] = "ROOT";
    RouteNames[RouteNames["HOME"] = 30] = "HOME";
    RouteNames[RouteNames["HOME_OVERVIEW"] = 31] = "HOME_OVERVIEW";
    RouteNames[RouteNames["OVERVIEW_WALLET_DETAILS"] = 32] = "OVERVIEW_WALLET_DETAILS";
    RouteNames[RouteNames["HOME_BALANCE"] = 33] = "HOME_BALANCE";
    RouteNames[RouteNames["MARKETPLACE"] = 34] = "MARKETPLACE";
    RouteNames[RouteNames["MARKETPLACE_LIST"] = 35] = "MARKETPLACE_LIST";
    RouteNames[RouteNames["CONFIRM_EMAIL"] = 36] = "CONFIRM_EMAIL";
    RouteNames[RouteNames["CHANGE_PASSWORD"] = 37] = "CHANGE_PASSWORD";
    RouteNames[RouteNames["SUPPORT"] = 38] = "SUPPORT";
    RouteNames[RouteNames["DEV_COMPONENTS"] = 39] = "DEV_COMPONENTS";
})(RouteNames || (RouteNames = {}));
